<template>
  <div style="height: 900px;">
    <div class="title"> Disapproved Articles</div>
    <el-table :border="parentBorder" style="width: 100%" height="400"
      stripe @expand-change="((row, expandedRows)=>{getQuestions(row, expandedRows, articles1)})" :data="articles1" row-key="id">
      <el-table-column type="expand">
        <template #default="props">
          <div style="margin: 10px auto; display: flex; justify-content: space-around;">
            <div v-if="props.row.questions.length != 0">
              <el-card v-for="(question, index) in props.row.questions" v-bind:key="index" class="box-card" style="width: 500px; margin-top: 10px;">
                <template #header>
                  <div class="card-header" style="display: flex; justify-content: space-between;">
                    <div>Question{{index+1}}: {{ question.question }}</div>
                    <div>Status: <el-tag :type="getStatus(question.status).tag">{{ getStatus(question.status).txt }}</el-tag> </div>
                  </div>
                </template>
                <div>
                  <el-descriptions :column="column">
                    <el-descriptions-item label="Choice1">
                      {{question.choice1}}
                    </el-descriptions-item>
                    <el-descriptions-item label="Choice2">
                      {{question.choice2}} 
                    </el-descriptions-item>
                    <el-descriptions-item label="Choice3">
                      {{question.choice3}}
                    </el-descriptions-item>
                    <el-descriptions-item label="Choice4">
                      {{question.choice4}}
                    </el-descriptions-item>
                    <el-descriptions-item label="Correct Choice">
                      {{getAns(question.correctChoice)}}
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
              </el-card>
            </div>
            <el-card style="margin-top: 10px;">
              <template #header>
                <div class="card-header" style="display: flex; justify-content: space-between;">
                  <div>Rejection Feedback from Moderator</div>
                </div>
              </template>
              <div v-if="props.row.feedback != ''">
                <lay-quote>{{props.row.feedback}}</lay-quote>
                <div style="display: flex; flex-direction: row-reverse; color: #2727a1; font-family: Poppins-Italic; font-size: 12px;">
                  <div>{{ props.row.feedbackTime }}</div>
                </div>
              </div>
              <div v-else style="height:100%">
                <lay-empty description="No Feedback" style="height: 80px;"></lay-empty>
              </div>
            </el-card>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="title in tableTitleList"
        :prop="title.prop"
        :label="title.name"
        :key="title.prop"
        :index="title.index">
      </el-table-column>
      <el-table-column fixed="right" label="Moderator" width="140">
        <template #default="scope">
          <el-tag type="warning">{{scope.row.moderName}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="route.query.admin > 1" fixed="right" label="Operation" width="100">
        <template #default="scope">
          <div style="display: flex"> 
            <el-button type="danger" link size="small" @click="handleDelete(scope.row)">Delete</el-button>    
          </div>  
        </template>
      </el-table-column>
    </el-table>

    <div class="title"> Approved Articles with disapproved questions</div>
    <el-table :border="parentBorder" style="width: 100%" height="400"
      stripe @expand-change="((row, expandedRows)=>{getQuestions(row, expandedRows, articles2)})" :data="articles2" row-key="id">
      <el-table-column type="expand">
        <template #default="props">
          <div style="margin: 10px auto; display: flex; justify-content: space-around;">
            <div v-if="props.row.questions.length != 0">
              <el-card v-for="(question, index) in props.row.questions" v-bind:key="index" class="box-card" style="width: 500px; margin-top: 10px;">
                <template #header>
                  <div class="card-header" style="display: flex; justify-content: space-between;">
                    <div>Question{{index+1}}: {{ question.question }}</div>
                    <div>Status: <el-tag :type="getStatus(question.status).tag">{{ getStatus(question.status).txt }}</el-tag> </div>
                  </div>
                </template>
                <div>
                  <el-descriptions :column="column">
                    <el-descriptions-item label="Choice1">
                      {{question.choice1}}
                    </el-descriptions-item>
                    <el-descriptions-item label="Choice2">
                      {{question.choice2}} 
                    </el-descriptions-item>
                    <el-descriptions-item label="Choice3">
                      {{question.choice3}}
                    </el-descriptions-item>
                    <el-descriptions-item label="Choice4">
                      {{question.choice4}}
                    </el-descriptions-item>
                    <el-descriptions-item label="Correct Choice">
                      {{getAns(question.correctChoice)}}
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
              </el-card>
            </div>
            <el-card style="margin-top: 10px;">
              <template #header>
                <div class="card-header" style="display: flex; justify-content: space-between;">
                  <div>Rejection Feedback from Moderator</div>
                </div>
              </template>
              <div v-if="props.row.feedback != ''">
                <lay-quote>{{props.row.feedback}}</lay-quote>
                <div style="display: flex; flex-direction: row-reverse; color: #2727a1; font-family: Poppins-Italic; font-size: 12px;">
                  <div>{{ props.row.feedbackTime }}</div>
                </div>
              </div>
              <div v-else style="height:100%">
                <lay-empty description="No Feedback" style="height: 80px;"></lay-empty>
              </div>
            </el-card>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="title in tableTitleList"
        :prop="title.prop"
        :label="title.name"
        :key="title.prop"
        :index="title.index">
      </el-table-column>
      <el-table-column fixed="right" label="Moderator" width="140">
        <template #default="scope">
          <el-tag type="warning">{{scope.row.moderName}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="route.query.admin > 1" fixed="right" label="Operation" width="100">
        <template #default="scope">
          <div style="display: flex"> 
            <el-button type="danger" link size="small" @click="handleDelete(scope.row)">Delete</el-button>    
          </div>  
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { formatDateTime } from '@/utils/dateFormatter'
import { getAns, getStatus } from '@/utils/codeToString'
import { ref } from 'vue'
import { get, post } from '../../api'
import { ElMessage, ElMessageBox } from "element-plus"
import { useRoute } from 'vue-router'

export default {
  name: "Disapproved",
  setup() {
    const route = useRoute()
    const parentBorder = ref(false)
    const childBorder = ref(false)
    const tableTitleList = [
      { prop: "name", name: "Name" },
      { prop: "createTime", name: "CreateTime" },
      { prop: "topic", name: "Topic" },
      { prop: "category", name: "Category" },
      { prop: "overview", name: "Overview" },
      { prop: "uname", name: "User" },
    ]
    const articles1 = ref([])
    const articles2 = ref([])
    const column = ref(2)
    const active = ref("1")
    const uid = ref(-1);
    const uname = ref("");

    if (sessionStorage.getItem("token")) {
      post(`/user/logged`).then((res) => {
        if (res.res == 1) {
          uid.value = res.user.uid;
          uname.value = res.user.uname;
        } else {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem('Authorization');
        }
      });
    }

    function handleDelete(row) {
      ElMessageBox.confirm(
        "Are you sure to delete this article record?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          post("/admin/delete-article", { articleId: row.id, moderator:uid.value }).then((res) => {
            if (res.res == 1) {
              ElMessage({
                type: "success",
                message: "Delete completed",
              });
              load();
            } else {
              ElMessage({
                type: "error",
                message: "Error, delete canceled",
              });
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    }

    function getQuestions(row, expandedRows, articles) {
      if (expandedRows.length > 0) {
        post('/question/get-question', { articleId: row.id }).then((res) => {
          articles.forEach((element, index) => {
            if (element.id == row.id) {
              articles[index].questions = res.questions
              console.log(row.questions);
            }
          });
        })

        get('/reject/get-by-article', { "id": row.id }).then((res) => {
          row.feedback = res.reject.feedback
          row.feedbackTime = formatDateTime(new Date(res.reject.time), 'yyyy-MM-dd HH:mm:ss')
        })
      }
    }

    function load() {
      post('/admin/disapproved-article').then((res) => {
        articles1.value = res.disapproved
        const { userNames, moderators } = res;
        articles1.value.forEach((article, index) => {
          article.questions = []
          article.uname = userNames[index] || "undefined user";
          article.moderName = moderators[index] || "default";
          let old = new Date(article.createTime)
          article.createTime = formatDateTime(old, 'yyyy-MM-dd HH:mm:ss')
        })
      })

      post('/admin/partial-disapproved-article').then((res) => {
        articles2.value = res.disapproved
        const { userNames, moderators } = res;
        articles2.value.forEach((article, index) => {
          article.questions = []
          article.uname = userNames[index] || "undefined user";
          article.moderName = moderators[index] || "default";
          let old = new Date(article.createTime)
          article.createTime = formatDateTime(old, 'yyyy-MM-dd HH:mm:ss')
        })
      })
    }

    load()

    return {
      parentBorder,
      childBorder,
      articles1,
      articles2,
      tableTitleList,
      getQuestions,
      getAns,
      getStatus,
      column,
      active,
      handleDelete,
      route
    }
  }
}
</script>

<style scoped>
  .title{
    display: block;
    margin-top: 30px;
    /* margin-bottom: 20px; */
    width: 100%;
    color: #111880;
    text-align: center;
    font-size: 20px;
    font-family: "Poppins-ExtraBold";
  }
</style>
  